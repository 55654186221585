window.displayUserFields = function(role) {
  if (role == "branch") {
    $("#user_code").parent().hide();
    $("#user_first_name").parent().hide();
    $("#user_last_name").parent().hide();
  } else {
    $("#user_code").parent().show();
    $("#user_first_name").parent().show();
    $("#user_last_name").parent().show();
  }

  let roles_ignore_permissions = [
    "branch",
    "customer_admin",
    "superadmin"
  ];
  if (roles_ignore_permissions.includes(role)) {
    $("#user_permissions").parents(".form-group").hide();
  } else {
    $("#user_permissions").parents(".form-group").show();
  }

  let requiredText = ' <abbr title="Pflichtfelder">*</abbr>';
  let codeLabel = $(".form-group.user_code label");
  let branchIdLabel = $(".form-group.user_branch_id label");

  if (role == "employee_with_code") {
    $("#user_email").parent().hide();
    codeLabel.append(requiredText);
  } else {
    $("#user_email").parent().show();
    codeLabel.html(codeLabel.html().split(requiredText)[0]);
  }

  if (role == "branch") {
    branchIdLabel.append(requiredText);
  } else {
    branchIdLabel.html(branchIdLabel.html().split(requiredText)[0]);
  }
}

$(document).on('change', 'select.user-role', function () {
  displayUserFields($(this).val());
});

document.addEventListener('turbolinks:load', function() {
  if ($('.execute-displayUserFields').length == 0) { return }

  displayUserFields($('select.user-role').val());
});